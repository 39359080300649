import { createAction, props } from '@ngrx/store';

export const getGeozones = createAction('[Geozones] Get');
export const storeGeozones = createAction('[Geozones] Store', props<{ list: any }>());
export const updateGeozoneById = createAction(
  '[Geozones] Update geozone by id',
  props<{ updatedGeozone: any; params?: any }>(),
);

export const deleteGeozoneById = createAction(
  '[Geozones] Delete geozone by id',
  props<{ deletedGeozoneId: any }>(),
);
export const addGeozone = createAction(
  '[Geozones] Add new geozone',
  props<{ createdGeozone: any; params?: any }>(),
);

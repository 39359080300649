import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  public selectedObjects: any = new BehaviorSubject([]);
  public drawMapObjects = new BehaviorSubject([]);
  public isVisibleObjectsName = new BehaviorSubject(false);
  public isVisibleGeozoneName = new BehaviorSubject(false);

  public updateTrackObjectPoint = new Subject();
  public panTo = new Subject();
  public setDrawMode = new Subject();
  public changeDrawOptions = new Subject();
  public clearMapGeozone = new Subject();
  public changeObjectMarkerPos = new Subject();
  public deleteObjectMarker = new Subject();
  public editGeozone = new Subject();

  public showStartTrack = new Subject();
  public showFinishTrack = new Subject();
  public drawStopsForTrack = new Subject();
  public drawParkingsForTrack = new Subject();
  public drawRefuelsForTrack = new Subject();
  public drawPlumsForTrack = new Subject();
  public drawOverSpeedForTrack = new Subject();
  public drawStartEndsForTrack = new Subject();

  public zoomOnMap = new Subject();
  public zoomBuildingOnMap = new Subject();

  public objForPopup = new Subject();

  constructor(
    private translate: TranslateService,
    private httpClient: HttpClient,
  ) {}

  getAddressString(
    address: any,
    addressElements: string[] = ['road', 'house_number', 'city', 'town', 'state', 'country'],
  ): string {
    return !address
      ? '-'
      : addressElements
          .filter((element: any) => address[element])
          .map((element: any) => address[element])
          .join(', ');
  }

  getAddressByCoordinates(lat: number, lng: number) {
    return this.httpClient
      .post('geocoding/reverse/', {
        coordinates: [{ lat, lng }],
      })
      .pipe(
        map((res: any) => {
          const key = Object.keys(res)[0];
          return res[key].address;
        }),
      );
  }

  getAddress(coordinates: any) {
    return this.httpClient.post('geocoding/reverse/', {
      coordinates,
    });
  }
}
